@mixin fadeIn {
    animation: fadeIn 0.3s ease forwards;
    backface-visibility: hidden;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}