@mixin xlUp {
    @media (min-width: 1400px) {
        @content;
    }
}

@mixin large {
    @media (max-width: 1400px) {
        @content;
    }
}

@mixin medium {
    @media (max-width: 1200px) {
        @content;
    }
}

@mixin small {
    @media (max-width: 992px) {
        @content;
    }
}

@mixin Msmall {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin Xsmall {
    @media (max-width: 576px) {
        @content;
    }
}