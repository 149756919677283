
    @import "mediaQueries.scss"; 
    @import "animation.scss";
html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  font-family: Verdana, Geneva, sans-serif;
  background-color: var(--color-back);

}

button {
  font-family: Verdana, Geneva, sans-serif;
}

a {
  color: inherit;
  font-family: Verdana, Geneva, sans-serif;
}


* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

li {
  list-style-type: none;
}

:root {
  --color-text: #1375a2;
  --color-text-medium: rgb(203, 236, 255);
  --color-text-light: rgba(245, 245, 245, 0.98);
  --color-seconday: #12354e;
  --color-back: #031d30;
}

.adsbygoogle {
  position: relative;
  width: 100% !important;
}

.ad__leaderboard {
  z-index: 500;
  margin: 2rem auto;
  width: 970px !important;
  height: 90px !important;
  min-height: 90px !important;


  @include small {
    width: 728px !important;
    height: 90px !important;
    min-height: 90px !important;
  }

  @include Msmall {
    width: 468px !important;
    height: 60px !important;
    min-height: 60px !important;
  }

  @include Xsmall {
    width: 320px !important;
    height: 50px !important;
    min-height: 50px !important;
  }
}

.ad__square {
  width: 250px !important;
  height: 250px !important;
  margin: 0 auto;
}

.ad__skyscraper {
  width: 120px !important;
  height: 600px !important;
  min-width: 120px !important;
  margin: 0rem auto;


  @include xlUp {
    width: 300px !important;
    min-width: 300px !important;
  }

  @include medium {
    width: 728px !important;
    height: 90px !important;
    min-height: 90px !important;
  }

  @include Msmall {
    width: 468px !important;
    height: 60px !important;
    min-height: 60px !important;
  }

  @include Xsmall {
    width: 320px !important;
    height: 50px !important;
  }
}